@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  /* --swiper-navigation-size: 10px !important; */
  --swiper-theme-color: #494b4e !important;
}

@media (max-width: 640px) {
  :root {
    --swiper-navigation-size: 20px !important;
  }
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.max-text-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
}

.offerCardColorGradient {
  background-image: linear-gradient(90deg, #fad207, #ff5f5f);
}

.btnbgcolor {
  background-image: linear-gradient(90deg, #e54a00, #f24e00 55%);
}

.b {
  border: 1px solid red;
}

.max-text-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-appearance: none;
  @apply bg-black;

  /* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.motion {
  transition: all 3s;
}

/* /////////////////// */

.DefaultProgressBar__vertical {
  width: 24px;
  height: 100%;
}
/* range btw min and max  */
.DefaultProgressBar_progressBar {
  position: absolute;
}

.DefaultProgressBar_progressBar__vertical {
  height: 100%;
  width: 24px;
}

.DefaultProgressBar_background__vertical {
  height: 100%;
  top: 0px;
  width: 15px;
}

.DefaultProgressBar_background__horizontal {
  height: 8px;
  top: 0px;
}

.DefaultHandle_handle {
  width: 1rem;
  height: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  background-color: black;
  border-radius: 50%;
  outline: none;
  z-index: 2;
  box-shadow: 0 2px 2px #181616;
}

.DefaultHandle_handle:focus {
  box-shadow: #abc4e8 0 0 1px 1px;
}
/* ********  inside circlce ******** */
/* .DefaultHandle_handle:after {
  content: "";
  display: block;
  position: absolute;
  background-color: #1f2124;
  border: 1px solid red;

}

.DefaultHandle_handle:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #1f2124;
  border: 1px solid red;

} */
/* ********  inside circlce end ******** */

/* ****** circle ***** */
.DefaultHandle_handle__horizontal {
  margin-left: -12px;
  top: -5px;
}

.DefaultHandle_handle__horizontal:before {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 10px;
}

.DefaultHandle_handle__horizontal:after {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 13px;
}

.DefaultHandle_handle__disabled {
  border-color: #dbdbdb;
}

.DefaultBackground {
  background-color: #353434;
  height: 15px;
  width: 100%;
  border: 1px solid #d8d8d8;
  position: relative;
}

/* full background  */

.DefaultBackground_background__horizontal {
  height: 10px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  border-radius: 10%;
}

.DefaultBackground_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}
/* kahi bu add kar yah pe margin de skte he  */
.rheostat {
  position: relative;
  overflow: visible;
  margin-top: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px;
  }
}

.rheostat__vertical {
  height: 100%;
}

.handleContainer {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  position: absolute;
}

.rheostat_background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
}

.rheostat_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
}

.rheostat_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}

.fixedhandled {
  -webkit-backface-visibility: hidden;
}

/* .introtooltip{
  border: 1px solid red !important;
} */

.introjs-tooltiptext {
  padding: 10px !important;
  text-align: center !important;
}

.introjs-bullets {
  text-align: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
